import * as React from 'react';
import {Link} from 'gatsby';
import _ from 'lodash';
import Img from 'gatsby-image';
import postCardModule from './post-card.module.scss';

interface PostCardProps {
  image?: any;
  title: string;
  description?: string;
  url: string;
  date?: string;
  tags?: [];
  className?: string;
  imageType?: 'fixed' | 'fluid';
}

const PostCard: React.FC<PostCardProps> = ({
                                             image,
                                             title,
                                             description,
                                             url,
                                             date,
                                             tags,
                                             className,
                                             imageType,
                                             ...props
                                           }) => {
  // Add all classes to an array
  const addAllClasses = ['postCard'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }

  return (
    <div className={`${addAllClasses.join(' ')} ${postCardModule.postCardWrapper}`} {...props}>
      <Link to={`/blogs${url}`}>
        <div className={`${postCardModule.postDetails} post_details`}>
          {date && (
            <div
              dangerouslySetInnerHTML={{
                __html: date,
              }}
              className={postCardModule.postDate}
            />
          )}

          <div>
            <h2 className={postCardModule.postTitle}>
              <a>{title}</a>
            </h2>

            {description && (
              <p
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
                className={postCardModule.postDescription}
              />
            )}

            {tags == null ? null : (
              <div className={postCardModule.postTags}>
                {tags.map((tag: string, index: number) => (
                  <Link key={index} to={`/tags/${_.kebabCase(tag)}/`}>
                    {`#${tag}`}
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>

        {image == null ? null : (
          <div className={postCardModule.postImage}>
            {imageType === 'fluid' ? (
              <Img fluid={image} alt="post preview"/>
            ) : (
              <Img fixed={image} alt="post preview"/>
            )}
          </div>
        )}
      </Link>
    </div>
  );
};

PostCard.defaultProps = {
  imageType: 'fluid',
};

export default PostCard;
