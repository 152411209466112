import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Posts from "../containers/posts/posts";

type BlogsPageProps = {};

const BlogsPage: React.FunctionComponent<BlogsPageProps> = () => {
    return (
        <Layout>
            <SEO
                title="Blogs"
                description="Bao Pham's blogs"
            />

            <Posts />
        </Layout>
    );
};

export default BlogsPage;
